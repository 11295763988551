import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby"
import styled from "styled-components";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import { useForm } from "react-hook-form";

const TrendyStatstikyInner = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px 25px;
  
  @media (min-width: 900px) {
    padding: 80px 25px;
  }

  h1 {
    font-family: NewKansas;
    font-size: 28px;
    line-height: 1.3;
    color: #243A58;
    margin-bottom: 30px;
    
    @media (min-width: 900px) {
      font-size: 36px;
      line-height: 1.2;
    }
  }

  .description {
    font-family: Visuelt-Regular;
    font-size: 18px;
    line-height: 1.6;
    color: #394B5C;
    margin-bottom: 40px;

    @media (min-width: 900px) {
      font-size: 20px;
    }
  }

  .download-section {
    background: #F7F9FB;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
    
    .buttony {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      display: block;
      background: #1D3A5B;
      border-radius: 5px;
      padding: 16px 0;
      text-decoration: none;
      transition: background 0.2s ease;

      &:hover {
        background: #081C33;
      }
    }

    .citation {
      font-family: Visuelt-Regular;
      font-size: 15px;
      color: #666;
      text-align: center;
      margin-top: 15px;
    }
  }

  .contact-section {
    border-top: 1px solid #EAEAEA;
    padding-top: 40px;

    h2 {
      font-family: Visuelt-Medium;
      font-size: 20px;
      color: #243A58;
      margin-bottom: 25px;
    }

    .contact-details {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      
      @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
      }

      .contact-person {
        font-family: Visuelt-Regular;
        
        .name {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #243A58;
          margin-bottom: 5px;
        }
        
        .role {
          font-size: 15px;
          color: #666;
          margin-bottom: 10px;
        }
        
        .email, .phone {
          font-size: 15px;
          color: #394B5C;
          text-decoration: none;
          display: block;
          margin-bottom: 5px;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;



const TrendyStatstiky = () => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <Layout V2>
      <SEO
        title="Statistiky pohřební služby Goodbye za rok 2024"
        customTitle
        description="Proč se Češi bojí, že rodina nebude mít peníze na jejich pohřeb? A proč nechtějí o smrti mluvit? Stáhněte si celý report zdarma v PDF."
      />

      <TrendyStatstikyInner>
        <h1>Strach z financování pohřbu i zájem o ekologické trendy.<br/>Statistiky pohřební služby Goodbye za rok 2024</h1>
        
        <p className="description">
          Jaké jsou pohřebnické trendy v České republice? Proč se Češi bojí, že rodina nebude mít peníze na jejich pohřeb? A proč nechtějí o smrti mluvit? Stáhněte si celý report v PDF.
        </p>

        <div className="download-section">
          <a className="buttony" href="/pohrebni-statistiky-2024.pdf" download>
            Stáhnout celý report (PDF)
          </a>
          <p className="citation">Nezapomeňte nás prosím citovat :-)</p>
        </div>

        <div className="contact-section">
          <h2>Kontakt pro média</h2>
          
          <div className="contact-details">
            <div className="contact-person">
              <p className="name">Lucie Kohoutková</p>
              <p className="role">PR manažerka</p>
              <a className="email" href="mailto:kohoutkova@goodbye.cz">kohoutkova@goodbye.cz</a>
              <a className="phone" href="tel:+420725300189">+420 725 300 189</a>
            </div>

            <div className="contact-person">
              <p className="name">Jiří Štěpánek</p>
              <p className="role">Zakladatel</p>
              <a className="email" href="mailto:stepanek@goodbye.cz">stepanek@goodbye.cz</a>
              <a className="phone" href="tel:+420605176305">+420 605 176 305</a>
            </div>
          </div>
        </div>
      </TrendyStatstikyInner>
    </Layout>
  )
}



export const query = graphql`
  query {

    big: file(relativePath: { eq: "pohrebPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "pohrebPozadiSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default TrendyStatstiky